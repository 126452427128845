import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import { navigate } from "gatsby"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import MenuTemplate from "../templates/menuTemplate"
import { AllLocations } from "../components/all-locations"
import { NavMenuProvider } from "../context/nav-menu"
import { Navigation } from "../components/shared/navigation"
import { MacBackGround } from "../components/shared/background"
import LocalMenu from "../../static/assets/TMS-MENU-REVISED-JAN-2020.jpg"
import { AppLogo } from "../components/shared/logo"
import TallyMac from "../../static/assets/macShack.svg"

import { useCateringForm, Title, SuccessMessage, Row } from "./catering"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { isFuture, isValid, format } from "date-fns"
import { Form, Field } from "react-final-form"
import CateringTemplate from "../templates/cateringTemplate"
import InputField from "../components/shared/input-field"
import { Card } from "../components/shared/card"
import { ErrorMsg } from "../components/shared/error-message"
import { DatePicker } from "../components/shared/date-picker"
import { TimePicker } from "../components/shared/time-picker"
import { useMobileDetection } from "../hooks/useMobileDetection"
import { Btn } from "../components/shared/button"
import { colors } from "../theme"
import { CenteredContent } from "../components/shared/centered-content"
import LinearProgress from "@material-ui/core/LinearProgress"

const MenuBoardSection = styled.section`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`

const MenuBoard = styled.a`
  text-decoration: none;
  min-width: 160px;
  ${tw`bg-gray-800 hover:bg-gray-900 tracking-wide text-yellow-400 font-bold rounded border-b-2 border-gray-500 hover:border-gray-600 shadow-md py-2 px-6 inline-flex justify-center`}
`

const ConstructionMessage = styled.p`
  color: ${colors.tallyYellow};
  font-size: 1.2em;
`

export default () => {
  const {
    selectedDate,
    setSelectedDate,
    hasValidDateAndTime,
    errorMsg,
    serverState,
    submitting,
    classes,
    cateringValidation,
    handleSubmission,
    shouldShowError,
  } = useCateringForm()

  const { isUsingMobile } = useMobileDetection()

  return (
    <NavMenuProvider>
      <MacBackGround>
        {/* <Navigation />
        <MenuTemplate /> */}
        <AppLogo src={TallyMac} alt="logo" onClick={() => navigate("/")} />
        <CenteredContent>
          <Card
            backgroundColor={colors.tallyGrey}
            style={{ width: "85%", height: "auto", margin: "1rem" }}
          >
            <Title>Book Tally Mac Shack!</Title>
            <Form
              onSubmit={handleSubmission}
              validate={cateringValidation}
              render={formProps => (
                <form onSubmit={formProps.handleSubmit}>
                  <input
                    type="text"
                    name="_gotcha"
                    style={{ display: "none" }}
                  />
                  <Row isUsingMobile={isUsingMobile}>
                    <Field
                      data-testid="catering-customer-name"
                      id="catering-customer-name"
                      name="name"
                      label="Name"
                      className={classes.textField}
                      style={{ minWidth: "223px" }}
                      margin="normal"
                      component={InputField}
                    />
                    <Field
                      data-testid="catering-customer-phone"
                      id="catering-customer-phone"
                      name="phone"
                      label="Phone"
                      style={{ minWidth: "223px" }}
                      className={classes.textField}
                      margin="normal"
                      component={InputField}
                    />
                    <Field
                      data-testid="catering-customer-email"
                      id="catering-customer-email"
                      name="email"
                      label="Email"
                      style={{ minWidth: "223px" }}
                      className={classes.textField}
                      margin="normal"
                      component={InputField}
                    />
                  </Row>
                  <Row isUsingMobile={isUsingMobile}>
                    <Field
                      data-testid="catering-customer-event-location"
                      id="catering-customer-event-location"
                      name="eventLocation"
                      label="Event Location"
                      style={{ minWidth: "223px" }}
                      className={classes.textField}
                      margin="normal"
                      component={InputField}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        id="date-picker-inline"
                        disableToolbar
                        style={{
                          paddingRight: isUsingMobile ? undefined : ".5rem",
                        }}
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        label="Date"
                        value={selectedDate}
                        onChange={date => setSelectedDate(date)}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                      <TimePicker
                        id="time-picker"
                        margin="normal"
                        style={{
                          paddingLeft: isUsingMobile ? undefined : ".5rem",
                        }}
                        label="Time picker"
                        value={selectedDate}
                        onChange={date => setSelectedDate(date)}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Row>
                  <Row>
                    <Field
                      data-testid="catering-customer-notes"
                      id="catering-customer-notes"
                      name="notes"
                      label="Notes"
                      style={{ width: "85%" }}
                      className={classes.textField}
                      placeholder="Special request and expected guest count"
                      variant="outlined"
                      component={InputField}
                    />
                  </Row>
                  {shouldShowError(formProps.values) && (
                    <ErrorMsg>{errorMsg}</ErrorMsg>
                  )}
                  {serverState && !serverState.ok && (
                    <ErrorMsg>
                      Unable to submit request, please try again
                    </ErrorMsg>
                  )}
                  {serverState && serverState.ok && (
                    <SuccessMessage>
                      Thank you for your submission! We'll be in touch shortly
                    </SuccessMessage>
                  )}
                  <Btn
                    disabled={
                      formProps.hasValidationErrors ||
                      formProps.submitting ||
                      !hasValidDateAndTime
                    }
                    style={{ margin: "1rem 0" }}
                    type="submit"
                  >
                    Submit Request
                  </Btn>
                  {submitting && <LinearProgress />}
                </form>
              )}
            />
            <CateringTemplate />
          </Card>
        </CenteredContent>

        <CenteredContent>
          <Card
            backgroundColor={colors.tallyGrey}
            style={{ width: "85%", height: "auto", margin: "2rem 0" }}
          >
            <ConstructionMessage>
              Web App coming soon. In the meantime feel free to take a look at
              our menu board.
            </ConstructionMessage>
            <MenuBoardSection>
              <MenuBoard
                href={LocalMenu}
                style={{ marginRight: ".5rem" }}
                onClick={() =>
                  trackCustomEvent({
                    category: "Menu Board",
                    action: "Click",
                    label: "Checking out store front menu board",
                  })
                }
              >
                Menu Board
              </MenuBoard>
            </MenuBoardSection>
          </Card>
        </CenteredContent>
        {/* <AllLocations /> */}
      </MacBackGround>
    </NavMenuProvider>
  )
}
