import React, { useEffect, useState } from "react"
import { unfold } from "ramda"
import { graphql, StaticQuery } from "gatsby"
import Grid from "@material-ui/core/Grid"
import styled from "styled-components"
import tw from "tailwind.macro"
import { wrap } from "@popmotion/popcorn"
import { motion, AnimatePresence } from "framer-motion"

import { ResponsiveImage } from "../components/shared/image"
import { CenteredContent } from "../components/shared/centered-content"
import { Header, Title, Description } from "../components/shared/text"
import { useMobileDetection } from "../hooks/useMobileDetection"
import { colors } from "../theme"

// #region Styles
const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 50px 20px 50px;
`

const FlexBox = styled.div`
  display: flex;
  flex-direction: ${({ isUsingMobile }) => (isUsingMobile ? "column" : "row")};
  align-items: center;
  justify-content: space-between;
`

const Img = styled(motion.div)`
  ${tw`flex-none bg-cover rounded text-center overflow-hidden`}
`

const Card = styled(motion.div)`
  min-height: 19em;
  max-width: 600px;
  @media (min-width: 750px) {
    max-width: 950px;
  }
  ${tw`relative rounded block md:flex items-center shadow-xl`}
`
const Border = styled.div`
  background-color: ${colors.tallyGrey};
  ${tw`border-r border-b border-l border-grey-light lg:border-l-0 lg:border-t lg:border-grey-light rounded p-4 flex flex-col justify-between leading-normal`};
`

const DescriptionContainer = styled(motion.div)`
  min-width: 4em;
  margin: ${({ isUsingMobile }) =>
    isUsingMobile ? undefined : "0 15px 0 25px"}; */
`

const Padding = tw.div`p-12 md:pr-24 md:pl-16 md:py-12`
const ButtonLeft = styled(motion.button)`
  outline: none;
  cursor: pointer;
  background-color: ${colors.tallyDarkGrey};
  ${tw`absolute top-0 mt-32 left-0 rounded-full shadow-lg h-12 w-12 text-2xl text-yellow-600 hover:text-yellow-400 focus:text-yellow-400 -ml-6`}
`
const ButtonRight = styled(motion.button)`
  outline: none;
  cursor: pointer;
  background-color: ${colors.tallyDarkGrey};
  ${tw`absolute top-0 mt-32 right-0 rounded-full shadow-lg h-12 w-12 text-2xl text-yellow-600 hover:text-yellow-400 focus:text-yellow-400 -mr-6`}
`
const Arrow = tw.span`block`

const Dot = styled(motion.div)`
  width: 15px;
  height: 15px;
  margin: 0 5px;
  cursor: pointer;
  background: ${props =>
    props.isActive ? colors.tallyYellow : colors.tallyYellowDark};
  border-radius: 50%;

  ${tw`shadow-md `}
`

// #endregion

// #region Motion
const variants = {
  enter: direction => ({
    x: direction < 0 ? -1000 : 1000,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: direction => ({
    zIndex: 0,
    x: direction < 0 ? 1000 : -1000,
    opacity: 0,
  }),
  visible: {
    opacity: 1,
    transition: {
      delay: 0.2,
    },
  },
  hidden: { opacity: 0 },
}

// #endregion

// #region Utils
const makeArray = length => n => (n > length ? false : [n, n + 1])

// #endregion

const MenuTemplate = ({ data }) => {
  const {
    allMarkdownRemark: { nodes },
  } = data // data.allMarkdownRemark.nodes holds our menu items
  const { isUsingMobile } = useMobileDetection()

  const [[page, direction], setPage] = useState([0, 0])
  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, nodes.length, page)

  const paginate = newDirection => setPage([page + newDirection, newDirection])

  /**
   * Experimenting with distilling swipe offset and velocity into a single variable, so the
   * less distance a user has swiped, the more velocity they need to register as a swipe.
   * Should accommodate longer swipes and short flicks without having binary checks on
   * just distance thresholds and velocity > 0.
   */
  const swipeConfidenceThreshold = 10000
  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity
  }

  return (
    <>
      <CenteredContent>
        <Header>Classic Macs</Header>
      </CenteredContent>
      <AnimatePresence initial={false} custom={direction}>
        <Container>
          <Card
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 200 },
              opacity: { duration: 0.2 },
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x)

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1)
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1)
              }
            }}
          >
            <Border>
              <FlexBox isUsingMobile={isUsingMobile}>
                {nodes.length && (
                  <CenteredContent>
                    <Img
                      style={{
                        backgroundImage: ` url('${nodes[imageIndex].frontmatter.image}-/preview/250x250/}')`,
                        width: "250px",
                        height: "250px",
                      }}
                      title={nodes[imageIndex].frontmatter.title}
                      initial="hidden"
                      animate="visible"
                      variants={variants}
                    />
                  </CenteredContent>
                )}
                <DescriptionContainer
                  isUsingMobile={isUsingMobile}
                  initial="hidden"
                  animate="visible"
                  variants={variants}
                >
                  <Title>{nodes[imageIndex].frontmatter.title}</Title>
                  <Description>
                    {nodes[imageIndex].frontmatter.description}
                  </Description>
                </DescriptionContainer>
              </FlexBox>
            </Border>
            <ButtonLeft
              onClick={() => paginate(-1)}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <Arrow style={{ transform: "scale(-1)" }}>&#x279c;</Arrow>
            </ButtonLeft>
            <ButtonRight
              onClick={() => paginate(1)}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <Arrow style={{ transform: "scale(1)" }}>&#x279c;</Arrow>
            </ButtonRight>
          </Card>
        </Container>
      </AnimatePresence>
      <CenteredContent>
        {nodes.length &&
          unfold(makeArray(nodes.length), 1).map((_, index) => (
            <Dot
              key={`circle-${index}`}
              whileTap={{ scale: 0.9 }}
              whileHover={{ scale: 1.3 }}
              isActive={index === imageIndex}
              onClick={() => setPage([index, index])}
            />
          ))}
      </CenteredContent>
    </>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query MenuTemplate {
        allMarkdownRemark(
          filter: { frontmatter: { path: { regex: "/menu/i" } } }
        ) {
          nodes {
            frontmatter {
              image
              title
              description
            }
            id
          }
        }
      }
    `}
    render={data => <MenuTemplate data={data} />}
  />
)
