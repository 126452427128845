import React from "react"
import SwipeableViews from "react-swipeable-views"
import { useTheme } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Box from "@material-ui/core/Box"
import StoreMallDirectory from "@material-ui/icons/StoreMallDirectory"
import styled from "styled-components"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import LocationTemplate from "../../templates/locationTemplate"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import { StoreLocation, CampusLocation } from "../store-location"
import { CenteredContent } from "../shared/centered-content"
import { BackgroundColor } from "../shared/background"
import { Header } from "../shared/text"
import { colors } from "../../theme"

const Container = styled.div`
  padding: 20px 0 20px 0;
`

const a11yProps = index => ({
  id: `full-width-tab-${index}`,
  "aria-controls": `full-width-tabpanel-${index}`,
})

const BorderTopRadius = {
  borderTopLeftRadius: ".25rem",
  borderTopRightRadius: ".25rem",
}

const BorderBottomRadius = {
  borderBottomLeftRadius: ".25rem",
  borderBottomRightRadius: ".25rem",
}

export const AllLocations = () => {
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
    // google analytics tracker for when a user clicks on food trucks tab
    if (newValue === 1) {
      trackCustomEvent({
        category: "Food Trucks Tab",
        action: "Click",
        label: "Checking out food trucks tab",
      })
    }
  }

  const handleChangeIndex = index => {
    setValue(index)
  }

  return (
    <Container>
      <CenteredContent style={{ paddingBottom: "5px" }}>
        <Header>Where to find us</Header>
      </CenteredContent>
      <CenteredContent>
        <BackgroundColor
          color={colors.tallyGrey}
          style={{
            boxShadow:
              "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
            minWidth: "400px",
            ...BorderBottomRadius,
            ...BorderTopRadius,
          }}
        >
          <AppBar
            position="static"
            style={{
              backgroundColor: `${colors.tallyGrey}`,
              minWidth: "400px",
              ...BorderTopRadius,
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              style={{
                backgroundColor: `${colors.tallyGreyInset}`,
                ...BorderBottomRadius,
                ...BorderTopRadius,
              }}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="location tabs"
            >
              <Tab
                {...a11yProps(0)}
                label={
                  <>
                    <StoreMallDirectory />
                    <span>Store Front</span>
                  </>
                }
              />
              <Tab
                {...a11yProps(1)}
                label={
                  <>
                    <LocalShippingIcon />
                    <span>Food Trucks</span>
                  </>
                }
              />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <Box p={3}>
              <StoreLocation />
              <CampusLocation />
            </Box>
            <Box p={3}>
              <LocationTemplate />
            </Box>
          </SwipeableViews>
        </BackgroundColor>
      </CenteredContent>
    </Container>
  )
}
